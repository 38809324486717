/* eslint-disable no-invalid-this */
import Axios from 'axios';
import Cookies from 'js-cookie';
import 'regenerator-runtime/runtime.js';
import swal from '@sweetalert/with-react';

const lsObj = {
    ACCESS_TOKEN: Cookies.get('Authorization'),
    REFRESH_TOKEN: Cookies.get('refresh-token'),
    fileId: Cookies.get('fileId'),
    type: Cookies.get('type')
};
localStorage.setItem('sandbox_user', JSON.stringify(lsObj));
const BASE_URL = 'https://tempsandboxserver.codeyoung.com';
const api = function (baseUrl) {
    this.apiBase = baseUrl;
    this.axios = Axios.create({
        baseURL: this.apiBase,
        timeout: 100000000
    });
    this.axios.defaults.headers.post['Content-Type'] = 'application/json';
    this.axios.defaults.headers.post['Cache-Control'] = 'no-store';
    this.axios.interceptors.request.use(
        req => {
            req.headers['Authorization'] = `Bearer ${Cookies.get('Authorization')}`;
            req.headers['refresh-token'] = Cookies.get('refresh-token');
            return req;
        },
        e => Promise.reject(e)
    );

    this.axios.interceptors.response.use(
        res => {
            return res;
        },
        async error => {
            const originalRequest = error.config; 
            if (
                error.response &&
                error.response.status === 401
            ) {
                originalRequest._retry = true;
                const expiredToken = Cookies.get('refresh-token')
                let access_token;
                try {
                    access_token = await refreshAccessToken(expiredToken);
                } catch (e) {
                    swal(
                        {
                            title: 'Login expired. Please go back to Workbench.',
                            icon: 'error'
                        }
                    );
                    return Promise.reject();
                }
                // * Set Global access token
                this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + access_token.data.ACCESS_TOKEN;
                // * Set access token for current Instance ( Orginial Request )
                originalRequest.headers["Authorization"] =
                "Bearer " + access_token.data.ACCESS_TOKEN;
                let result;
                try {
                    result = await this.axios(originalRequest);
                } catch (e) {
                    console.log("Error : ", e);
                }
                return result;
            }
            if (
                error.response &&
                error.response.status === 403
            ) {
                swal(
                    {
                        title: 'Login expired. Please go back to Workbench.',
                        icon: 'error'
                    }
                );
            }
            return Promise.reject(error);
        }
    );

    const refreshAccessToken = async refreshToken => {
        const refreshToken1 = await this.axios.post('https://tempsandboxserver.codeyoung.com/getNewRefreshToken', {
            refreshToken
        });
        Cookies.set('Authorization', refreshToken1.data.ACCESS_TOKEN, {path: '/'});
        Cookies.set('refresh-token', refreshToken1.data.REFRESH_TOKEN, {path: '/'});
        return refreshToken1;
    };
};

api.prototype.getApiBase = function () {
    return this.apiBase;
};

api.prototype.get = function (url, params = {}, data = {}) {
    return this.axios({
        method: 'get',
        url,
        params,
        data
    });
};

api.prototype.post = function (url, data = {}, params = {}) {
    return this.axios({
        method: 'post',
        url,
        data,
        params
    });
};

api.prototype.put = function (url, data, params = {}) {
    return this.axios({
        method: 'put',
        url,
        data,
        params
    });
};

api.prototype.delete = function (url, data) {
    return this.axios({
        method: 'delete',
        url,
        data
    });
};

const API = new api(BASE_URL);

export {API};
